(function () {
	'use strict';
	angular
		.module('sidebar')
		.controller('SidebarCtrl', [
			'$scope',
			'utilities',
			'dataStore',
			'seedcodeCalendar',
			'manageFilters',
			SidebarCtrl,
		]);

	function SidebarCtrl(
		$scope,
		utilities,
		dataStore,
		seedcodeCalendar,
		manageFilters
	) {
		//Load sidebar object from our model and watch for future updates
		$scope.sidebar = seedcodeCalendar.get('sidebar');

		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';

		$scope.isSalesforce = fbk.isSalesforce();

		//Initialize filter state:
		$scope.sidebar.filterState = manageFilters.getFilterState();

		$scope.$on('updateFilters', function (e, result) {
			//Wrap this in scope apply so any changes are visible automatically without user interaction
			$scope.$evalAsync(function () {
				$scope.sidebar.filterState = manageFilters.getFilterState();
			});
		});

		$scope.changeSidebarTab = function (name) {
			$scope.sidebar.view = name;
			dataStore.saveState('sidebarTab', name);
		};

		$scope.changeSidebarPanel = function (name, change) {
			if (!$scope.sidebar.panel) {
				$scope.sidebar.panel = {};
			}

			$scope.sidebar.panel[name] = change;
		};
	}
})();
